import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const EditLink = (props) => {
  let { to, style, className } = props
  const css = {
    color: 'inherit',
    ...style
  }
  return (
    <Link
      to={to}
      style={css}
      className={className}>
        <LinkBtn>
          <i className="fas fa-pencil-alt" />
        </LinkBtn>
    </Link>
  )
}

const LinkBtn = styled.div`
  border-radius: 50%;
  width: 50px
  height: 50px;
  line-height: 50px;
  background-color: #3F3F3F;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  i {
    color: white;
  }
`

export default EditLink
