
const color = {
  bg: '#00734C',
  font: 'white',
  border: '#d3d6db',
  remove: '#FB8B8B',
  lightGrey: '#d0d1d3',
  grey: '#414141',
  lightGreen: '#00b186',
  green: '#003e31',
}

export {
  color,
}
