import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const CartBtn = (props) => {
  const onClick = () => {
    let { name, onClick } = props
    if (onClick) onClick(name)
  }

  const {
    disabled,
    loading
  } = props

  const css = {
    width: '100%',
  }

  let click
  if (!loading && !disabled) {
    click = onClick
  }
  return (
    <PageView>
      <Button
        disabled={disabled}
        style={css}
        loading={loading}
        onClick={click}>
        <Icon className="fas fa-shopping-bag" />
        เพิ่มรายการ
      </Button>
    </PageView>
  )
}

const PageView = styled.div`
  button {
    border: 1px solid #9e3641;
    color: white;
    background-color: #9e3641;
  }

  button:hover {
    border: 1px solid #9e3641;
    color: #9e3641;
    background-color: white;
  }
`

const Icon = styled.i`
  margin-right: 4px;
`

export default CartBtn
