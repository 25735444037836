import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'

import { CloseBtn } from '../../components/circle'
import { color } from '../../definition'

const ImgDialog = (props) => {
  const [selected, setSelected] = useState(0)

  const {
    index = 0,
    visible,
    onClose,
    img_list = [],
  } = props

  const getImgCss = () => {
    const width = 2000
    const height = 1400

    return {
      width: `${width}px`,
      height: `${height}px`,
    }
  }

  const gotoCenter = useCallback((i, top, left) => {
    setTimeout(() => {
      const img_doc = document.getElementById(`img-${i}`)
      img_doc.scroll({
        top,
        left,
        behavior: 'smooth',
      })
    }, 250)
  }, [])

  const selectImage = useCallback((i) => {
    setSelected(i)
    gotoCenter(i, 450, 400)

  }, [gotoCenter])

  useEffect(() => {
    if (visible) {
      selectImage(index)
    }
  }, [visible, index, selectImage])

  const onPrevious = () => {
    const previous = selected - 1
    if (previous >= 0) {
      selectImage(previous)
    }
  }

  const onNext = () => {
    const next = selected + 1
    if (next < img_list.length) {
      selectImage(next)
    }
  }

  const cssImg = getImgCss()
  const content = img_list.map((url, index) => {
    const css = { display: selected === index ? undefined : 'none' }
    return (
      <ImgSlider id={`img-${index}`} style={css} key={index}>
        <img src={url} style={cssImg} alt="" />
      </ImgSlider>
    )
  })

  const menus = img_list.map((url, index) => {
    return selected === index ? (
      <MenuActiveItem src={url} key={index} />
    ) : (
      <MenuItem src={url} key={index} onClick={() => selectImage(index)} />
    )
  })

  let nextBtn
  let backBtn
  if (img_list.length > 1) {
    backBtn = (
      <PreviousBtn onClick={onPrevious}>
        <LeftCircleOutlined style={{ color: color.green }} />
      </PreviousBtn>
    )

    nextBtn = (
      <NextBtn onClick={onNext}>
        <RightCircleOutlined style={{ color: color.green }}  />
      </NextBtn>
    )
  }

  return (
    <Modal
      title=""
      footer=""
      visible={visible}
      className="editor-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%" >
      <PageView>
        <PageArea>
          <PageContent>
            <TopMenu>
              <CloseBtn title="" onClick={onClose} />
            </TopMenu>

            <div>

              <SlideShow>
                {backBtn}
                {content}
                {nextBtn}
              </SlideShow>

              <MenuList>
                {menus}
              </MenuList>
            </div>
          </PageContent>
        </PageArea>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`

const PageArea = styled.div`
  align-items: center;
  display: flex;
  height: 90vh;
`

const PageContent = styled.div`
  padding: 10px 20px;
  background-color: white;
  margin: 0 auto;
  border-radius: 10px;
  width: 1250px;
  max-height: 90vh;
  max-width: 90vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 1100px;
  }

  @media screen and (max-width: 1024px) {
    width: 800px;
  }

  @media screen and (max-width: 768px) {
    width: 700px;
    padding: 5px 10px;
  }

  @media screen and (max-width: 430px) {
    width: 385px;
  }

  @media screen and (max-width: 375px) {
    width: 350px;
  }
`

const PreviousBtn = styled.span`
  font-size: 30px;
  position: absolute;
  left: 10px;
  top: 45%;
  z-index: 5;
  cursor: pointer;
`

const NextBtn = styled.span`
  font-size: 30px;
  position: absolute;
  right: 10px;
  top: 45%;
  z-index: 5;
  cursor: pointer;
`

const TopMenu = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
`

const SlideShow = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #C4C3C3;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const MenuList = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  img {
    margin: 0px 4px;
  }
`

const ImgSlider = styled.div`
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
  width: 100%;
  height: 60vh;
  max-height: 550px;
  overflow: scroll;
  text-align: center;

  img {
    object-fit: scale-down;
    height: 500px;
  }

  @media screen and (max-width: 1440px) {

  }

  @media screen and (max-width: 1024px) {
    max-height: 500px;
  }

  @media screen and (max-width: 800px) {
    max-height: 450px;
  }

  @media screen and (max-width: 430px) {
    max-height: 400px;
  }

  @-webkit-keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`

const MenuActiveItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px ${color.bg};
  object-fit: cover;
  margin: 0px;
  cursor: pointer;
`

const MenuItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px transparent;
  object-fit: cover;
  margin: 0px;
  filter: brightness(0.5);
  cursor: pointer;
`

/*
<ToolBar>
  <i className="fas fa-minus" onClick={onZoomout} />
  <SliderTool>
    <Slider
      tooltipVisible={false}
      value={zoom}
      onChange={(val) => onSetZoom(val)}
    />
  </SliderTool>
  <i className="fas fa-plus" onClick={onZoomin} />

  <span>{`${zoom}%`}</span>
</ToolBar>
const ToolBar = styled.div`
  width: 100%;
  height: 48px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  background-color: ${color.bg};
  margin: 0 auto;
  padding-left: 83px;
  span {
    font-size: 20px;
    margin-left: 20px;
    width: 100px;
  }

  i {
    font-size: 20px;
    cursor: pointer;
  }
`

const barcolor = '#A7FED0'
const SliderTool = styled.div`
  width: 185px;
  height: 40px;
  margin-left: 25px;
  margin-right: 25px;

  .ant-slider {
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 8px;
      border-radius: 6px;
    }

    .ant-slider-handle {
      width: 24px;
      height: 24px;
      margin-top: -8px;
      background-color: ${barcolor};
      border: solid 2px #dddddd;
      z-index: 3;
    }

    .ant-slider-track {
      background-color: ${barcolor};
    }
  }

  .ant-slider:hover > .ant-slider-track {
    background-color: ${barcolor};
  }

  .ant-slider:hover > .ant-slider-handle {
    border-color: ${color.bg} !important;
  }
`
*/
export default ImgDialog
