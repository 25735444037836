import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { color } from 'definition'
import { Input } from 'components/input'
import { KeyBtn } from 'components/button'
import { format, product, ga } from 'utils'

const AddCart = (props) => {
  const [quantity, setQuantity] = useState(1)
  const { doc = {} } = props
  const { status, unit_type } = doc

  const onChange = (val) => {
    setQuantity(val)
  }

  const onAddOrder = () => {
    props.order.addProduct(doc, quantity)
    props.order.setVisible(true)
    ga.click(`add product by info ${quantity}`)
  }

  const price = format.toMoney(doc.price)
  const isDisabled = product.isDisabled(status)
  const text = isDisabled ? 'สินค้าหมด' : 'เพิ่มลงรถเข็น'
  const note = product.getStatusNote(status)
  const content = note === '' ? undefined : (
    <Note>หมายเหตุ: {note}</Note>
  )

  return (
    <PageView>
      <Price>
        <label>ราคา</label>
        <div>
          {price} บาท / {unit_type}
        </div>
      </Price>

      <Row>
        <Col lg={6} md={7} xs={8}>
          <Input
            min={1}
            max={50000}
            value={quantity}
            onChange={onChange}
            isNumber={true} />
        </Col>
        <Col lg={18} md={17} xs={16}>
          <Btn>
            <KeyBtn text={text} disabled={isDisabled} onClick={onAddOrder} />
          </Btn>
        </Col>
      </Row>
      {content}
    </PageView>
  )
}

const PageView = styled.div`
  padding: 16px;
  border: 1px solid ${color.border};
  border-radius: 5px;
  background-color: white;
  width: 100%;

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
`

const Price = styled.div`
  label {
    font-size: 14px;
    color: #6C6C6C;
  }

  font-size: 16px;
  margin-bottom: 24px;
  color: #00734C;
`

const Btn = styled.div`
  margin-left: 8px;
`

const Note = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: #6C6C6C;
`

export default inject('order')(observer(AddCart))
