import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'antd'

import Loading from 'components/loading/Loading'
import { Link } from 'components/link'
import { color } from 'definition'
import { format, message } from 'utils'
import { Meta, HtmlContent } from 'components/display'
import BreadcrumbView from 'pages/Breadcrumb'

const BrandCategory = (props) => {
  const [loading, setLoading] = useState(false)

  const { code } = useParams()
  const brand_code = code
  const {
    brand_categories = [],
    filter_brand = {}
  } = props.brand.toJS()

  const onGetList = useCallback(async (c) => {
    try {
      setLoading(true)
      await props.brand.getList()
      await props.brand.getBrandCategories(c)
    } catch (e) {
      message.error({
        title: 'แสดงรายการประเภทสินค้า',
        message: e.message,
      })
    }

    setLoading(false)
  }, [props.brand])

  const content = brand_categories.map((it, index) => {
    const {
      code,
      name,
      img_url,
    } = it
    const link = `/product.list/brand/${brand_code}/${code}?name=${name}`
    return (
      <Col key={index} xl={4} lg={6} md={8} xs={12}>
        <Link to={link}>
          <Category>
            <Img src={img_url} />
            <Name>
              {format.limitText(name, 50)}
            </Name>
          </Category>
        </Link>
      </Col>
    )
  })

  const getName = (url, i = 0) => {
    const index = url.lastIndexOf('/')
    const name = decodeURI(index === -1
    ? `File (${i})`
    : url.substr(index + 1))
    return name
  }

  const { file_list = [] } = filter_brand
  const file_content = file_list.map((url, index) => {
    return (
      <Col
        key={index} lg={8} md={12} sm={24} xs={24} >
        <FileBlock>
          <File href={url} target="_blank">
            <i className="fas fa-file-download" />
            {getName(url, index + 1)}
          </File>
        </FileBlock>
      </Col>
    )
  })
  const brandInfoContent = (
    <Content>
      <Row>
        <Col lg={6} md={8} sm={12} xs={24} style={{textAlign: 'center'}}>
          <LogoImg src={filter_brand.img_url} />
        </Col>
        <Col lg={18} md={16} sm={12} xs={24}>
          <BrandDetail>
            <BrandTitle>ยี่ห้อ {filter_brand.name}</BrandTitle>
            <Section>
              รายละเอียด
            </Section>
            <Name>
              <HtmlContent content={filter_brand.detail} />
            </Name>

            <Section>
              เอกสารสินค้า
            </Section>
            <FileList>
              <Row gutter={8}>
                {file_content.length === 0 ? '-' : file_content}
              </Row>
            </FileList>
          </BrandDetail>
        </Col>
      </Row>
    </Content>
  )

  useEffect(() => {
    onGetList(code)
  }, [onGetList, code])

  return (
    <PageView>
      <Meta title="ประเภทสินค้าตามยี่ห้อ" />
      <BreadcrumbView
        by_brand={true}
        brand_code={code}
        category_list={[filter_brand]} />

      <Loading loading={loading} />
      {brandInfoContent}
      <Title>ประเภทสินค้า</Title>
      <Row>
        {content}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

const Title = styled.h2`
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Category = styled.div`
  color: black;
  background-color: white;
  padding: 10px;
  border: 1px solid ${color.border};
  cursor: pointer;
  text-align: center;
  height: 100%;

  :hover {
    border: 2px solid ${color.bg};
    padding: 9px;
  }
`
const LogoImg = styled.img`
  width: 200px;
  background-color: white;
  padding: 0px 10px;

  @media screen and (max-width: 1024px) {
    min-height: 20px;
    max-width: 200px;
  }

  @media screen and (max-width: 768px) {
    min-height: 20px;
    max-width: 200px;
  }

  @media screen and (max-width: 480px) {
    min-height: 16px;
    max-width: 200px;
  }

  @media screen and (max-width: 430px) {
    max-width: 150px;
    margin: 0 auto;
  }

  @media screen and (max-width: 425px) {
    min-height: 16px;
    max-width: 150px;
    margin: 0 auto;
  }
`

const Img = styled.img`
  width: 100%;
  min-height: 60px;
`

const Name = styled.div`
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 44px;
`

const Content = styled.div`
  width: 100%;
  color: black;
  background-color: white;
  padding: 10px;
  border: 1px solid ${color.border};
  border-radius: 4px;
`

const BrandTitle = styled.h2`
  margin-top: 1px;
  font-weight: bold;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BrandDetail = styled.div`
  width: 100%;
  color: black;
  background-color: white;
  padding: 10px;
`

const Section = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`

const FileList = styled.div`
  margin-top: 5px;
`

const FileBlock = styled.div`
  margin-bottom: 10px;
`

const File = styled.a`
  color: ${color.bg};

  i {
    font-size: 28px;
    margin-right: 8px;
  }

  &:hover {
    color: #1890ff;
  }
`

export default inject('brand', 'category')(observer(BrandCategory))
