import React from 'react'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

const OpenBtn = (props) => {
  let {
    loading = false, onClick,
    title = 'Open'
  } = props

  let content
  let click
  if (loading === false) {
    content = (<i className="fas fa-chevron-right" />)
    click = onClick
  }
  return (
    <Page>
      <Tooltip placement="top" title={title}>
        <Button shape="circle" loading={loading} onClick={click}>
          {content}
        </Button>
      </Tooltip>
    </Page>
  )
}

const Page = styled.div`
  text-align: center;
  button {
    color: white;
    background-color: #404040;

    height: 45px
    width: 45px;
  }

  button > i {
    font-size: 16px;

  }

  button:hover {
    color: #404040;
    background-color: white;
    border-color: #404040;
  }
`

export default OpenBtn
