import React from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'

import { color } from '../../definition'

const InputToggle = (props) => {
  const {
    menus = [],
    value,
    disabled,
  } = props

  const onChange = (evt) => {
    if (props.onChange) {
      props.onChange(evt.target.value, props.name)
    }
  }

  const content = menus.map((item, index) => {
    const icon = item.value === value ? (
      <Icon className="fas fa-check-circle" />
    ) : undefined
    return (
      <Radio.Button value={item.value} key={index}>
        {icon}{item.name}
      </Radio.Button>
    )
  })

  const view = (
    <Radio.Group
      disabled={disabled}
      value={value}
      onChange={onChange}>
      {content}
    </Radio.Group>
  )

  const len = menus.length
  switch (len) {
    case 1:
      return <MainView1>{view}</MainView1>
    case 2:
      return <MainView2>{view}</MainView2>
    case 3:
      return <MainView3>{view}</MainView3>
    case 4:
      return <MainView4>{view}</MainView4>
    default:
      return <MainView>{view}</MainView>
  }
}

const MainView = styled.div`
  .ant-radio-group-outline {
    width: 100%;
    font-size: 12px;
  }
  .ant-radio-group-outline > .ant-radio-button-wrapper {
    width: 20%;
    text-align: center;
  }

  .ant-radio-group-outline > .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.65);
    background-color: #F1F1F1;
    border-color: #d9d9d9;
  }

  .ant-radio-group-outline > .ant-radio-button-wrapper-checked {
    color: white;
    background-color: ${color.bg};
    border-color: ${color.bg};
  }

  .ant-radio-group-outline:hover > .ant-radio-button-wrapper {
    color: ${color.bg};
  }

  .ant-radio-group-outline:hover > .ant-radio-button-wrapper-checked {
    color: white;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: ${color.bg};
}
`

const MainView1 = styled(MainView)`
  .ant-radio-group-outline > .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
  }
`

const MainView2 = styled(MainView)`
  .ant-radio-group-outline > .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
`

const MainView3 = styled(MainView)`
  .ant-radio-group-outline > .ant-radio-button-wrapper {
    width: 33%;
    text-align: center;
  }
`

const MainView4 = styled(MainView)`
  .ant-radio-group-outline > .ant-radio-button-wrapper {
    width: 25%;
    text-align: center;
  }
`

const Icon = styled.i`
  margin-right: 5px;
`

export default InputToggle
