import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Row, Col } from 'antd'
import queryString from 'query-string'

import Loading from 'components/loading/Loading'
import { message } from 'utils'
import FilterProduct from 'pages/filter'
import ProductViewGroup from 'pages/product.list/ProductViewGroup'
import ProductGroup from 'pages/product.list/ProductGroup'
import { FilterBtn } from 'components/button'
import { Meta, Pagination, NoProduct } from 'components/display'

import BreadcrumbView from './Breadcrumb'

const FindProductList = (props) => {
  const [loading, setLoading] = useState(false)
  const [isList, setIsList] = useState('list')

  const location = useLocation()

  const params = queryString.parse(location.search)
  const { keyword } = params
  const { display = {} } = props.find.toJS()

  const onGetList = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.find.getMenuProductCount({ keyword }),
        props.find.getProductCount({ keyword }),
      ])

      await props.find.getProductList({ keyword })
    } catch (e) {
      if (e.message === 'The regular expression contains an unclosed bracket expression.') {

      } else {
        message.error({
          title: 'แสดงรายการสินค้า',
          message: e.message,
        })
      }
    }

    setLoading(false)
  }, [props.find, keyword])

  useEffect(() => {
    onGetList()
  }, [onGetList, keyword])

  const getProductList = async (params) => {
    try {
      setLoading(true)
      const {
        group_list = [],
        category_list = [],
        brand_list = [],
      } = getFilterMenu(params.menu)

      await props.find.getProductList({
        ...params,
        group_list,
        category_list,
        brand_list,
      })

    } catch (e) {
      if (e.message === 'The regular expression contains an unclosed bracket expression.') {

      } else {
        message.error({
          title: 'แสดงรายการสินค้า',
          message: e.message,
        })
      }
    }

    setLoading(false)
  }

  const getFilterMenu = (menu = {}) => {
    const {
      group_list = [],
      category_list = [],
      brand_list = [],
    } = menu

    const group_code = group_list.filter(item => item.selected).map(it => it.item.code)
    const category_code = category_list.filter(item => item.selected).map(it => it.item.code)
    const brand_code = brand_list.filter(item => item.selected).map(it => it.item.code)

    return {
      group_list: group_code,
      category_list: category_code,
      brand_list: brand_code,
    }
  }

  const onSelectBrand = (item) => {
    const nDisplay = props.find.selectBrand(item)
    getProductList({ keyword, menu: nDisplay })
  }

  const onSelectCategory = (item) => {
    const nDisplay = props.find.selectCategory(item)
    getProductList({ keyword, menu: nDisplay })
  }

  const onSelectGroup = (item) => {
    const nDisplay = props.find.selectGroup(item)

    getProductList({ keyword, menu: nDisplay })
  }

  const onDisplayFilter = () => {
    const filter = document.getElementById('filter-body')
    if (filter) {
      const dis = filter.style.display
      filter.style.display = dis === 'block' ? 'none' : 'block'
    }
  }

  const onPage = async (index, per_page) => {
    getProductList({ index, keyword, menu: display })
  }

  const getBtn = (name, icon, value) => {
    return (
      <Btn className={isList === value ? 'active' : undefined} onClick={() => setIsList(value)}>
        {name} <i className={icon} />
      </Btn>
    )
  }

  let product_content = <NoProduct />
  let pagination
  if (loading === false && display.list.length > 0) {
    product_content = display.list.map((item, index) => {
      if (isList === 'product') {
        return (
          <ProductViewGroup
            key={index}
            item={item}
            isList={isList}
            prefix_url="/product" />
        )
      } else {
        return (
          <ProductGroup
            key={index}
            item={item}
            isList={isList}
            prefix_url="/product" />
        )
      }
    })

    pagination = (
      <Pagination
        text="จำนวนทั้งหมด"
        index={display.index}
        page={display.page}
        onChange={onPage} />
    )
  }

  return (
    <PageView>
      <Meta title="ค้นหาสินค้า" />
      <BreadcrumbView />

      <Row>
        <Col xl={5} lg={7} md={6} xs={24}>
          <Filter>
            <FilterBtn
              className="filter-btn"
              onClick={onDisplayFilter} />

            <div id="filter-body" >
              <FilterProduct
                group_list={display.group_list}
                onSelectGroup={onSelectGroup}

                brand_list={display.brand_list}
                onSelectBrand={onSelectBrand}

                category_list={display.category_list}
                onSelectCategory={onSelectCategory} />
            </div>
          </Filter>
        </Col>

        <Col xl={19} lg={17} md={18} xs={24}>
          <PaginationView>
            {pagination}
          </PaginationView>

          <Button>
            {getBtn('Grid', 'fas fa-grip-horizontal', 'product')}
            {getBtn('List', 'fas fa-list', 'list')}
            {getBtn('Table', 'fas fa-table', 'table')}
          </Button>

          <Loading loading={loading}>
            {product_content}
          </Loading>
          {pagination}
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

const Filter = styled.div`
  .filter-btn {
    display: none;
  }

  @media screen and (max-width: 430px) {
    .filter-btn {
      display: block;
      margin-bottom: 10px;
    }

    #filter-body {
      display: none;
    }
  }
`

const PaginationView = styled.div`
  margin-bottom: 10px;
`

const Button = styled.div`
  font-size: 14px;
  width: 100%;
  text-align: right;
`

const Btn = styled.span`
  padding: 0px 10px;
  cursor: pointer;

  &.active {
    color: #00734C;
  }
`

export default inject('find')(observer(FindProductList))
