import React from 'react'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

const RejectBtn = (props) => {
  let {
    loading = false, disabled = false,
    onClick, title = 'Cancel'
  } = props

  let content
  let click
  if (loading === false) {
    content = (<i className="fas fa-times" />)
    click = onClick
  }
  return (
    <Page>
      <Tooltip placement="top" title={title}>
        <Button
          shape="circle" disabled={disabled}
          loading={loading} onClick={click}>
          {content}
        </Button>
      </Tooltip>
    </Page>
  )
}

const Page = styled.div`
  button {
    color: #3F3F3F;
    background-color: #ffaaa6;

    height: 45px
    width: 45px;
  }

  button > i {
    font-size: 16px;
  }

  button:hover {
    color: #ffaaa6;
    background-color: white;
    border-color: #ffaaa6;
  }
`

export default RejectBtn
