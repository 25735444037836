import React from 'react'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

const SaveBtn = (props) => {
  let {
    loading = false, onClick,
    title = 'Save',
    disabled = false,
  } = props

  let content
  let click

  if (loading === false) {
    content = (<i className="far fa-save" />)
    click = onClick
  }
  return (
    <Page>
      <Tooltip placement="top" title={title}>
        <Button
          shape="circle"
          loading={loading}
          disabled={disabled}
          onClick={click}>
          {content}
        </Button>
      </Tooltip>
    </Page>
  )
}

const Page = styled.div`
  text-align: center;
  button {
    color: white;
    background-color: #042f4b;
    border: 2px solid #F1FEFF;
    height: 45px
    width: 45px;
  }

  button > i {
    font-size: 16px;
  }

  button:hover {
    color: #404040;
    background-color: white;
    border-color: #404040;
  }
`

export default SaveBtn
