import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const SeemoreBtn = (props) => {
  let {
    loading = false, onClick,
    text = 'See More'
  } = props

  let content
  if (loading === false) {
    content = (
      <>
        <i className="fas fa-search" />
        <span>{text}</span>
      </>
    )
  }
  return (
    <Page>
      <Button loading={loading} onClick={onClick}>
        {content}
      </Button>
    </Page>
  )
}

const color = '#383838'
const bg = 'white'

const Page = styled.div`
  button {
    font-size: 12px;
    color: ${color};
    background-color: ${bg};
    border-radius: 20px;
    padding: 0px 20px;
    width: 100%;
  }

  button > i {
    margin-right: 5px;
  }

  button:hover {
    color: ${bg};
    background-color: ${color};
    border-color: ${color};
  }
`

export default SeemoreBtn
