import styled from 'styled-components'

const Social = (props) => {
  return (
    <Page>
      <Title>
        ติดต่อเรา
      </Title>
      <div>
        เวลาทำการ : 8:00-17:00น
      </div>
      <div>
        มือถือ: 02-892-7946 , 092-265-8564
      </div>
      <div>
        <AppLink href="https://page.line.me/prathan" target="_blank">
          Line: @prathan
        </AppLink>
      </div>

      <Line />

    </Page>
  )
}


const Page = styled.div`
 padding-left: 10px;

 @media screen and (max-width: 430px) {
   padding-left: 0px;
 }
`

const Line = styled.div`
  margin-bottom: 20px;
`

const Title = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`

const AppLink = styled.a`
  color: white;
  &:hover {
    color: white;
    font-weight: bold;
  }
`
/*
<Title>
  ติดตามเรา
</Title>
<IconList>
  <i className="fab fa-facebook" />
  <i className="fab fa-twitter-square" />
  <i className="fab fa-instagram" />
  <i className="fab fa-youtube" />
</IconList>
const IconList = styled.div`
  font-size: 26px;
  i {
    margin-right: 10px;
  }
`
*/

export default Social
